<template>
    <div class="mb-4 ins_box">
        <div class="text-gray-400 title text-left duration-500 pc_show">账号安全</div>
        <div class="w-full duration-500">
            <div
                class="flex justify-between items-center item sm:py-5 w-full duration-500"
                v-for="(item,index) in list"
                :key="index"
            >
                <div class="flex items-center w-full duration-500">
                    <div>
                        <img :src="item.icon" alt />
                    </div>
                    <div class="sm:ml-4 title_box duration-500">
                        <div class="text-left flex items-center title w-full justify-between">
                            <div class="flex items-center duration-500 text-base">
                                <div>{{ item.title }}</div>
                                <div v-if="item.status" class="ml-2">{{ item.status }}</div>
                            </div>
                            <div>
                                <span
                                    class="phone_show text-sm text-primary modify"
                                    @click="goCommon(item.path)"
                                >修改</span>
                            </div>
                        </div>
                        <div
                            class="text-left text-sm text-gray-400 desc duration-500"
                        >{{ item.desc }}</div>
                    </div>
                </div>
                <div class="pc_show duration-500">
                    <el-button class="text-gray-400" @click="goCommon(item.path)">修改</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    icon: require('../../../../assets/images/personal/account1.png'),
                    path: "/index/personal/common?type=password",
                    title: "保护密码",
                    desc: "用户保护账号信息和登录安全"
                },
                {
                    icon: require('../../../../assets/images/personal/account2.png'),
                    path: "/index/personal/common?type=email",
                    title: "安全邮箱",
                    status: "未绑定",
                    desc: "安全邮箱可以用于登录火影账号，重置密码或其他安全认证"
                },
                {
                    icon: require('../../../../assets/images/personal/account3.png'),
                    path: "/index/personal/common?type=phone",
                    title: "安全手机",
                    status: "未绑定",
                    desc: "安全手机可以用于登录火影账号，重置密码或其他安全认证"
                }
            ]
        };
    },
    components: {

    },
    methods: {
        changeTab(index) {
            this.tabIndex = index
        },
        onSubmit() {

        },
        goCommon(path) {
            this.$router.push({ path })
        }
    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}
.item:not(:last-child) {
    border-bottom: 0.0625rem solid var(--border-base);
}

@media screen and (max-width: 640px) {
    .title_box {
        width: calc(100% - 1.875rem);
        margin-left: 0.625rem;
    }
    .title {
        div {
            font-size: 0.9375rem;
        }
        .modify {
            font-size: 0.8125rem;
        }
    }
    .desc {
        font-size: 0.75rem;
    }
    .item {
        padding: 0.9375rem 0;
        img {
            width: 1.25rem;
        }
    }
    .phone_show {
        display: block;
    }
    .pc_show {
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .item {
        img {
            width: 1.875rem;
        }
    }
    .phone_show {
        display: none;
    }
    .pc_show {
        display: block;
    }
}
@media screen and (min-width: 900px) {
    .title {
        font-size: 1.875rem;
    }
}
</style>